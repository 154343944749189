import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { AuthGuard } from '@core/guards/auth.guard';


@NgModule({
  imports: [RouterModule.forRoot([
    {
      path: '',
      loadChildren: () => import('./modules/public/public.module').then(m => m.PublicModule)
    },
    {
      path: '',
      loadChildren: () => import('./modules/authenticated/authenticated.module').then(m => m.AuthenticatedModule),
      canLoad: [AuthGuard],
    },
    { path: 'unauthorized', redirectTo: '/' },
  ], {
    anchorScrolling: 'enabled',
    enableTracing: false,
    onSameUrlNavigation: 'reload',
    scrollOffset: [0, 50],
    scrollPositionRestoration: 'enabled',
    paramsInheritanceStrategy: 'always',
  })],
  exports: [
    RouterModule
  ]
})
export class AppRoutingModule { }
