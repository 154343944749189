import { Injectable } from '@angular/core';
import { AbstractSecurityStorage } from 'angular-auth-oidc-client';


@Injectable()
export class AuthOidcCustomStore extends AbstractSecurityStorage {
  get storage(): Storage {
    return localStorage;
  }

  public read(key: string): any {
    const item = this.storage.getItem(key);
    return item != null
      ? JSON.parse(item)
      : null;
  }

  public clear(): void {
    this.storage.clear();
  }

  public write(key: string, value: any): void {
    this.storage.setItem(key, JSON.stringify(value || null));
  }

  public remove(key: string): void {
    this.storage.removeItem(key);
  }
}
