import { Component, inject, OnInit } from "@angular/core";
import { LoginResponse, OidcSecurityService } from "angular-auth-oidc-client";
import { catchError, finalize, tap } from "rxjs/operators";
import { throwError } from "rxjs";
import {
  LoadingScreenDependencies,
  LoadingScreenService
} from "@shared/components/loading-screen/loading-screen.service";
import { AuthService } from "@core/services/auth/auth.service";
import { Router } from "@angular/router";

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styles: []
})
export class AppComponent implements OnInit {
  readonly #authService = inject(AuthService);
  readonly #loadingScreenService = inject(LoadingScreenService);
  readonly #oidcSecurityService = inject(OidcSecurityService);
  readonly #router = inject(Router);

  ngOnInit(): void {
    this.#loadingScreenService.addDependant(LoadingScreenDependencies.AUTHENTICATION);

    this.#oidcSecurityService.checkAuth().pipe(
      tap((loginResponse: LoginResponse) => {

        if (loginResponse.isAuthenticated) {
          if (this.#authService.getStoredRememberMe()) {
            this.#authService.setStoredUser(
              loginResponse.userData.sub,
              loginResponse.userData.given_name,
              loginResponse.userData.family_name
            );
          }
        }
      }),
      catchError((e) => {
        void this.#router.navigate(['/']);
        return throwError(() => e);
      }),
      finalize(() => {
        this.#authService.authCompleted();
        this.#loadingScreenService.removeDependant(LoadingScreenDependencies.AUTHENTICATION);
      }),
    ).subscribe();
  }
}
