import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  CanActivateChild,
  CanLoad,
  Router,
  RouterStateSnapshot,
  UrlTree,
} from '@angular/router';
import { AuthService } from '@core/services/auth/auth.service';
import { AuthenticatedResult, OidcSecurityService } from 'angular-auth-oidc-client';
import { Observable } from 'rxjs';
import { map, take } from 'rxjs/operators';


@Injectable({
  providedIn: 'root',
})
export class AuthGuard implements CanActivate, CanActivateChild, CanLoad {
  constructor(
    private _authService: AuthService,
    private _oidcSecurityService: OidcSecurityService,
    private _router: Router,
  ) {
  }

  /**
   *
   */
  canLoad(): Observable<boolean | UrlTree> {
    return this._checkAuth(this._router.getCurrentNavigation()?.extractedUrl.toString() ?? '');
  }

  /**
   *
   */
  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean | UrlTree> {
    return this._checkAuth(state.url);
  }

  /**
   *
   */
  canActivateChild(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean | UrlTree> {
    return this._checkAuth(state.url);
  }

  /**
   *
   */
  private _checkAuth(url: string): Observable<boolean | UrlTree> {
    return this._oidcSecurityService.isAuthenticated$.pipe(
      take(1),
      map((authResult: AuthenticatedResult) => {
        if (!authResult.isAuthenticated) {
          // Store current url for redirect back once authenticated:
          this._authService.setStoredRedirectRoute(url);
          return this._router.createUrlTree(['/']);
        }

        return authResult.isAuthenticated;
      }),
    );
  }
}
